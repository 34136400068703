import api from '../../utils/api';

const Theses = {
  namespaced: true,
  state: () => ({
    theses: [], 
    lastThese: {},
    languages: [],
  }),
  mutations: {
   setTheses(state,payload) {
    state.theses = payload
   },
   setLanguages(state,payload) {
    state.languages = payload
   },
  },
  actions: {
    fetchThesesForApplication({ commit }, payload) {
        return api
        .get(`/applications/${payload.application_id}/theses/${payload.language}`)
        .then((res) => {
          if (res.status == 200) {
            commit("setTheses", res.data.data);
            return res.data;
          }
        })
        .catch((err) => {
         return err
        });
    },
    fetchAvailableLanguagesForApplication({ commit }, payload) {
      return api
      .get(`/applications/${payload.application_id}/theses/languages`)
      .then((res) => {
        if (res.status == 200) {
          commit("setLanguages", res.data.data);
          return res.data;
        }
      })
      .catch((err) => {
       return err
      });
  },

   

/* prepare for using vueX store 
async fetchLastTheseforApplication({ commit }, payload) {
  try {
    const res = await api.get(`/applications/${payload.application_id}/matches/parties/${payload.partyId}/votes`);
    
    if (res.status === 200) {
      const party1LastThese = res.data.data.find(item => item.party_id === 1);
      const party2LastThese = res.data.data.find(item => item.party_id === 2);
      if (party1LastThese) {
        commit("setLastThese", { party_id: 1, ...party1LastThese });
      }
      if (party2LastThese) {
        commit("setLastThese", { party_id: 2, ...party2LastThese });
      }

      return res.data;
    }
  } catch (err) {
    return err;
  }
} */

  },
  getters: {
    //getLastThese: (state) => state.lastThese,
  },
};

export default Theses;
