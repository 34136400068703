<template>
  <Drawer id="right-drawer" position="right" :style="{ zIndex: 9999 }">
    <div id="menu-items" class="flex flex-column">
      <p>VOTO</p>
      <a href="/">{{ t("menu", "start") }}</a>
      <a target="_blank" href="https://portal.voto.vote/">{{
        t("menu", "portal")
      }}</a>
      <a v-if="showTutorialMenu" @click="showTutorial()">{{
        t("menu", "tutorial")
      }}</a>
      <p>{{ t("menu", "about") }}</p>
      <a target="_blank" href="https://voto.vote/faq">{{ t("menu", "faq") }}</a>
      <a target="_blank" href="https://voto.vote/">{{ t("menu", "what") }}</a>
      <a target="_blank" href="https://voto.vote/datenschutz">{{
        t("menu", "data_protection")
      }}</a>
      <a target="_blank" href="https://voto.vote/impressum">{{
        t("menu", "legal_notice")
      }}</a>
    </div>

    <div class="bottom">
      <div class="version-container text-gray">
        <div>2024-07-22-fedaa65</div>
      </div>
    </div>

    <div>
      <b-button
          v-b-modal.change-appliction-modal
          class="language-button "
          variant="light"
        >
      </b-button>
      </div>

  </Drawer>
</template>
<script>
import { OnboardTour, VOTOTour } from "../../utils/tour.js";

export default {
  name: "RightDrawer",
  data() {
    return {
      showTutorialMenu: false,
    };
  },
  methods: {
    showTutorial() {
      this.$emit("close");
      localStorage.setItem("disable_tour", "false");
      if (this.$route.path == "/theses") {
        let oT = new OnboardTour(this, VOTOTour.Theses);
        oT.tour.start();
      } else if (this.$route.path == "/matches") {
        let oT = new OnboardTour(this, VOTOTour.Matches);
        oT.tour.start();
        // } else if (this.$route.path.includes("/match/")) {
        //   alert("Show detail match");
        //   let oT = new OnboardTour(this, VOTOTour.Match);
        //   oT.tour.start();
      }
    },
  },
  watch: {
    $route(to) {
      if (
        to.path == "/theses" ||
        to.path == "/matches"
        //to.path.includes("/match/")
      ) {
        this.showTutorialMenu = true;
      } else {
        this.showTutorialMenu = false;
      }
    },
  },
};
</script>

<style scoped>
#menu-items > * {
  border-bottom: 1px solid #d7d7d7;
  height: 2rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #6c757d;
}

#menu-items > a {
  color: var(--p-primary-color);
}

.bottom {
  position: fixed;
  bottom: 10px;
  margin-left: -15px;
  width: 20rem;
}

p {
  margin-left: 0;
}

.version-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: gray;
  font-size: 0.8rem;
}
</style>
