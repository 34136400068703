import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Timeline from "primevue/timeline";
import ProgressBar from "primevue/progressbar";
import Card from "primevue/card";
import Menubar from "primevue/menubar";
import ToggleSwitch from "primevue/toggleswitch";
import ProgressSpinner from "primevue/progressspinner";
import Drawer from "primevue/drawer";
import SelectButton from "primevue/selectbutton";
import Avatar from "primevue/avatar";
import MultiSelect from "primevue/multiselect";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import Popper from "vue3-popper";
import VueSocialSharing from "vue-social-sharing";
import SpeedDial from "primevue/speeddial";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Carousel from "primevue/carousel";
import Select from "primevue/select";
import InputText from "primevue/inputtext";
import Tooltip from "primevue/tooltip";

import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import de from "./locales/de.json";
import en from "./locales/de.json";
import localizerPlugin from "./plugins/localizer";
import store from "./store";

import "/node_modules/primeflex/primeflex.css";
import i18n from "./utils";
import { palette } from "@primevue/themes";
import logo from "./assets/img/voto_purple.png";

const VotoPreset = definePreset(Aura, {
  primitive: {
    votoPurple: palette("#8C0096"),
    votoBlue: palette("#34a1b7"),
    votoOldGold: palette("#d3c64a"),
    votoOrangeYellow: palette("#f4b02b"),
  },
  semantic: {
    primary: {
      50: "{votoPurple.50}",
      100: "{votoPurple.100}",
      200: "{votoPurple.200}",
      300: "{votoPurple.300}",
      400: "{votoPurple.400}",
      500: "{votoPurple.500}",
      600: "{votoPurple.600}",
      700: "{votoPurple.700}",
      800: "{votoPurple.800}",
      900: "{votoPurple.900}",
      950: "{votoPurple.950}",
    },
    secondary: {
      50: "{votoBlue.50}",
      100: "{votoBlue.100}",
      200: "{votoBlue.200}",
      300: "{votoBlue.300}",
      400: "{votoBlue.400}",
      500: "{votoBlue.500}",
      600: "{votoBlue.600}",
      700: "{votoBlue.700}",
      800: "{votoBlue.800}",
      900: "{votoBlue.900}",
      950: "{votoBlue.950}",
    },
    secondaryColor: "{secondary.500}",
    logoUrl: `url(${logo})`,
  },
});

const app = createApp(App).use(i18n);

app.use(store);
app.use(router);
app.use(localizerPlugin);
app.use(PrimeVue, {
  ripple: true,
  locale: de,
  theme: { preset: VotoPreset, options: { darkModeSelector: ".voto-dark" } },
});
app.use(PrimeVue, {
  ripple: true,
  locale: en,
  theme: { preset: VotoPreset, options: { darkModeSelector: ".voto-dark" } },
});
app.use(VueSocialSharing);
app.use(ConfirmationService);

app.component("PButton", Button);
app.component("ProgressBar", ProgressBar);
app.component("Card", Card);
app.component("Menubar", Menubar);
app.component("ToggleSwitch", ToggleSwitch);
app.component("ProgessSpinner", ProgressSpinner);
app.component("Drawer", Drawer);
app.component("SelectButton", SelectButton);
app.component("Avatar", Avatar);
app.component("MultiSelect", MultiSelect);
app.component("Accordion", Accordion);
app.component("AccordionPanel", AccordionPanel);
app.component("AccordionHeader", AccordionHeader);
app.component("AccordionContent", AccordionContent);
app.component("Popper", Popper);
app.component("SpeedDial", SpeedDial);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Carousel", Carousel);
app.component("Select", Select);
app.component("InputText", InputText);
app.component("Timeline", Timeline);
app.directive("tooltip", Tooltip);

app.use(store);

app.mount("#app");
