export default {
    install: (app) => {
      app.mixin({
        methods: {
            t(key,text) {
                return this.$primevue.config.locale[key][text]
            }
        },
      });
    },
  };