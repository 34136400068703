import Shepherd from "shepherd.js";
import "@/assets/shepherd/custom.css";

export const VOTOTour = {
  Theses: 0,
  Matches: 1,
  Match: 2,
};

export class OnboardTour {
  constructor(ctx, tourType) {
    this.app = ctx;
    this.tour = new Shepherd.Tour({
      keyboardNavigation: true,
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: false,
      },
    });
    var w = window.innerWidth;
    var isMobile = false;
    if (w < 720) {
      isMobile = true;
    }

    Shepherd.on("cancel", () => {
      localStorage.setItem("disable_tour", "true");
    });

    switch (tourType) {
      case VOTOTour.Theses:
        var progressIndicator = isMobile
          ? ".show-these-progress-mobile"
          : ".show-these-progress";
        var skipVote = isMobile
          ? ".skip-vote-button-mobile"
          : ".skip-vote-button";
        var previousThese = isMobile
        ? ".previous-these-mobile"
        : ".previous-these"
        
        this.tour.addSteps([
          {
            id: "step1",
            text: this.app.t("theses_tutorial", "step1"),
            buttons: [
              {
                text: this.app.t("tutorial", "no_thanks"),
                action: this.tour.cancel,
              },
              {
                text: this.app.t("tutorial", "start"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step2",
            text: this.app.t("theses_tutorial", "step2"),
            attachTo: {
              element: ".these-text",
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step3",
            text: this.app.t("theses_tutorial", "step3"),
            canClickTarget: false,
            attachTo: {
              element: "#button-container",
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step4",
            text: this.app.t("theses_tutorial", "step4"),
            attachTo: {
              element: progressIndicator,
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step5",
            text: this.app.t("theses_tutorial", "step5"),
            canClickTarget: false,
            attachTo: {
              element: skipVote,
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step6",
            text: this.app.t("theses_tutorial", "step6"),
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step7",
            text: this.app.t("theses_tutorial", "step7"),
            canClickTarget: false,
            beforeShowPromise() {
              return new Promise((resolve) => {
                const agreeButton = document.querySelector('.p-button-success');
                const disagreeButton = document.querySelector('.p-button-danger')
                agreeButton.addEventListener("click", () => {
                  resolve();
                });
                disagreeButton.addEventListener("click", () => {
                  resolve();
                });
                
              });
            },
            attachTo: {
              element: "#live-matches",
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step8",
            text: this.app.t("theses_tutorial", "step8"),
            canClickTarget: false,
            attachTo: {
              element: "#hide-matches-button",
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step9",
            text: this.app.t("theses_tutorial", "step9"),
            canClickTarget: false,
            attachTo: {
              element: previousThese,
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "laststep",
            text: this.app.t("theses_tutorial", "laststep"),
            buttons: [
              {
                text: this.app.t("tutorial", "finish"),
                action: this.tour.next,
              },
            ],
          },
        ]);
        break;

      case VOTOTour.Matches:
        var progressBar = isMobile
          ? "#candidate-match-0-mobile"
          : "#candidate-match-0";
        var shareMatches = isMobile
          ? "#share-matches-mobile .p-speeddial-button"
          : "#share-matches .p-speeddial-button";
        this.tour.addSteps([
          {
            id: "step1",
            text: this.app.t("matches_tutorial", "step1"),
            buttons: [
              {
                text: this.app.t("tutorial", "no_thanks"),
                action: this.tour.cancel,
              },
              {
                text: this.app.t("tutorial", "start"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step2",
            text: this.app.t("matches_tutorial", "step2"),
            attachTo: {
              element: progressBar,
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step3",
            text: this.app.t("matches_tutorial", "step3"),
            attachTo: {
              element: "#switch-matches",
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step4",
            text: this.app.t("matches_tutorial", "step4"),
            attachTo: {
              element: "#filter-matches",
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "continue"),
                action: this.tour.next,
              },
            ],
          },
          {
            id: "step5",
            text: this.app.t("matches_tutorial", "step5"),
            attachTo: {
              element: shareMatches,
              on: "bottom",
            },
            buttons: [
              {
                text: this.app.t("tutorial", "finish"),
                action: this.tour.next,
              },
            ],
          },
        ]);
        break;
    }
  }
}
