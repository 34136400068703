import { createRouter, createWebHistory } from "vue-router";
import { loadTheme } from "./modules/theme";

const routes = [
    {
        path: "/",
        component: () => import("./views/start.vue"),
    },
    {
        path: "/app/:id",
        component: () => import("./views/app.vue"),
    },
    {
        path: "/theses",
        component: () => import("./views/theses.vue"),
    },
    {
        path: "/matches",
        component: () => import("./views/matches.vue"),
    },
    {
        path: "/faq",
        component: () => import("./views/faq.vue"),
    },
    {
        path: "/match/candidates/:id",
        component: () => import("./views/match.vue"),
        meta: {comparison: "candidates"}
    },
    {
        path: "/match/parties/:id",
        component: () => import("./views/match.vue"),
        meta: {comparison: "parties"}
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("./views/dynamic.vue"),
    },

]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    mode: "history",
    history: createWebHistory(),
    routes,
  })
  
  router.beforeEach(async (to) => {
      if (to.path && to.path !== "/") {
        await loadTheme();
    }
  })

export default router;
