<template>
  <div>
    <router-view v-slot="{ Component, route }">
      <Topnav @openDrawer="visibleRight = true" />
      <RightDrawer @close="onClose" v-model:visible="visibleRight" />
      <transition>
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import Topnav from "../containers/navs/Topnav.vue";
import RightDrawer from "../containers/navs/RightDrawer.vue";

export default {
  data() {
    return {
      visibleRight: false,
    };
  },
  components: {
    Topnav,
    RightDrawer,
  },
  methods: {
    onClose() {
      this.visibleRight = false;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
  .fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
